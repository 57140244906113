import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <section className="errorSection">
      <div className="container-fluid">
        <div className="wrapperService">
          <h1>
            404
          </h1>
          <h2>
            The requested URL not found
          </h2>
          <Link className="btn" activeClassName="active" to={`${process.env.GATSBY_BLOG_URL}/`}>
            Go to main page
          </Link>
        </div>
      </div>
    </section>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
